import { render, staticRenderFns } from "./SourceTopicsTab.vue?vue&type=template&id=4c85faa6&scoped=true&"
import script from "./SourceTopicsTab.vue?vue&type=script&lang=js&"
export * from "./SourceTopicsTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c85faa6",
  null
  
)

export default component.exports